import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import AiService from '@/services/ai/ai.service.js'
import ContactService from '@/services/web-chat/contact.service.js'
import getEnv from '@/util/env'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment'
import { Agent } from '@/classes/chat/agent'
import EventBus from '@/util/EventBus'
import SvgIcon from '@/components/vue-chat/components/SvgIcon/SvgIcon'
import { VEmojiPicker } from 'v-emoji-picker'
import { Contact } from '@/models/web-chat/Contact.js'
import SelectCountry from '@/components/ContactSource/SelectCountry/SelectCountry.vue'
import CountryService from '@/services/country.service'
import Contacts from '@/components/WebChat/Contacts/Contacts.vue'
import ContactEditDialog from '@/components/WebChat/Contacts/ContactEditDialog.vue'
import { TextTag } from '@/classes/TextTag'
import { TextFormatParser } from '@/classes/TextFormatParser.js'
import * as linkify from 'linkifyjs'
import RoutesService from '@/services/whatsapp/routes.service'
import { Subscriptions } from '@/classes/helpers/Subscriptions'


const { io } = require('socket.io-client')
export default {
    name: 'Chat',
    components: {
        HeaderTopDashboard,
        SvgIcon,
        VEmojiPicker,
        SelectCountry,
        Contacts,
        ContactEditDialog,
    },
    data: function () {
        return {
            conversations: [],
            messages: [],
            selectedConversationIndex: null,
            currentConversation: null,
            text: '',
            sendingMessage: false,
            agents: [],
            currentAgent: null,
            showSearch: false,
            searchText: '',
            searchResults: 0,
            foundElements: [],
            currentFoundMessageIndex: -1,
            showEmojiPicker: false,
            emojiPickerTimeOpened: 0,
            showContactEdit: false,
            contact: new Contact(),
            countries: [],
            searchContactText: '',
            statusFilter: 1,
            dialogConfirmCloseConversation: false,
            loadingDialogCloseConversation: false,
            dialogConfirmOpenConversation: false,
            loadingDialogOpenConversation: false,
            currentComponent: 'inbox',
            contactDialogCtrl: {
                show: false,
            },
            textProps: {
                show: false,
                x: 0,
                y: 0,
            },
            textTag: null,
            textFormatParser: new TextFormatParser(),
            reactionTypes: [
                { type: 1, icon: 'fas fa-thumbs-up' },
                { type: 2, icon: 'fas fa-thumbs-down' },
            ],
            conversationIdToLoad: undefined,
        }
    },
    watch: {
        searchText () {
            this.cleanClassFound()
            if (this.searchText === '') {
                this.searchResults = 0
                return
            }

            const messagesContainer = document.getElementsByClassName('conversation-message')
            let results = 0
            const foundElements = []
            messagesContainer.forEach((element) => {
                console.log(element)
                if (element.outerText.toLowerCase().trim().includes(this.searchText.toLowerCase().trim())) {
                    foundElements.push(element)
                    results += 1
                }
            })

            this.foundElements = foundElements
            this.searchResults = results
            if (this.searchResults > 0) {
                this.currentFoundMessageIndex = -1
                this.nextSearch()
            }
        },
    },
    methods: {
        parseMessage (message) {
            message = this.textFormatParser.formatHtmlMessage(message)
            const links = linkify.find(message)
            links.forEach(link => {
                message = this.generateLink(message, link)
            })
           return message
        },
        generateLink (message, link) {
            const linkedText = message
                .substring(link.start)
                .replace(link.value, `<a href="${link.href}" target="_blank">${link.value}</a>`)
            return message.substring(0, link.start) + linkedText
        },
        addFormatText (type) {
            const replaced = this.textTag.addFormatText(type)
            this.text = replaced || this.textTag.textElement.value
        },
        setHtmlLink (message, link) {
            const messageStart = message.substring(0, link.start)
            const linkedValue = `<a href="${link.href}" target="_blank">${link.value}</a>`
            const linkedText = message
                .substring(link.start)
                .replace(link.value, linkedValue)
            link.start += linkedValue.length
            return messageStart + linkedText
        },
        showContacts () {
            this.currentComponent = 'contacts'
            this.loadContacts()
        },
        loadContacts () {
            this.$refs.contactsComponent.getData()
        },
        contactName (contact) {
            return (contact.firstname ?? '') + ' ' + (contact.lastname ?? '')
        },
        userForeground (item) {
            return item.status === 0 ? 'white' : 'grey darken-1'
        },
        userBackground (item) {
            return item.status === 0 ? 'grey darken-4' : 'grey lighten-3'
        },
        openConversation () {
            this.loadingDialogOpenConversation = true
            AiService.openConversation({ conversationId: this.currentConversation.id }).then((res) => {
                this.currentConversation.status = 1
                this.loadingDialogOpenConversation = false
                this.dialogConfirmOpenConversation = false
            })
        },
        preOpenConversation () {
            this.dialogConfirmOpenConversation = true
        },
        getAllCountries () {
            CountryService
                .getAllCountries()
                .then(
                    (response) => {
                        this.countries = response.map((country) => {
                            return {
                                value: country.iso_code_2,
                                text: `${country.name} +(${country.prefix})`,
                            }
                        })
                    },
                    () => {},
                )
        },
        showContactEditDrawer () {
            this.contactDialogCtrl.show = true
        },

        closeContactDrawer () {
            this.contactDialogCtrl.show = false
        },

        savedContact (contact) {
            this.contact.loadData(contact)
            ContactService.saveContact({ conversationId: this.currentConversation.id, contactId: this.contact.id }).then((contact) => {
                this.currentConversation.chat_contact_id = this.contact.id
                this.currentConversation.contact = contact
                this.closeContactDrawer()
            })
        },

        checkCloseEmojiPicker () {
            if (this.showEmojiPicker) {
                if (Date.now() > this.emojiPickerTimeOpened + 200) {
                    this.showEmojiPicker = false
                }
            }
        },
        setShowEmojiPicker () {
            this.showEmojiPicker = !this.showEmojiPicker
            if (this.showEmojiPicker) {
                this.emojiPickerTimeOpened = Date.now()
            }
        },

        selectEmoji (emoji) {
            const emojiCharacter = emoji.data
            const textInput = this.$refs.textInput.$refs.input
            const sentence = textInput.value
            const len = sentence.length
            let pos = textInput.selectionStart
            if (pos === undefined) {
                pos = 0
            }

            const before = sentence.substr(0, pos)
            const after = sentence.substr(pos, len)

            this.text = before + emojiCharacter + after

            this.$nextTick().then(() => {
                textInput.selectionStart = textInput.value.length
                textInput.click()
            })
            this.checkCloseEmojiPicker()
        },
        openSearch () {
            this.resetSearch()
            this.showSearch = true
            setTimeout(() => {
                document.getElementById('searchTextInput').focus()
            })
        },
        closeSearch () {
            this.cleanClassFound()
            this.showSearch = false
        },
        resetSearch () {
            this.searchText = ''
        },
        prevSearch () {
            if (this.currentFoundMessageIndex === 0) {
                this.currentFoundMessageIndex = this.foundElements.length - 1
            } else {
                this.currentFoundMessageIndex = this.currentFoundMessageIndex - 1
            }
            this.goToMessage(this.currentFoundMessageIndex)
        },
        nextSearch () {
            this.onEnter()
        },
        onEnter () {
            if (this.searchResults > 0) {
                if (this.currentFoundMessageIndex === this.foundElements.length - 1) {
                    this.currentFoundMessageIndex = 0
                } else {
                    this.currentFoundMessageIndex = this.currentFoundMessageIndex + 1
                }
                console.log(this.currentFoundMessageIndex)
                this.goToMessage(this.currentFoundMessageIndex)
            }
        },
        cleanClassFound () {
            this.foundElements.forEach(element => {
                element.classList.remove('found-text-conversation')
            })
        },
        goToMessage (index) {
            this.cleanClassFound()
            this.foundElements[index].classList.add('found-text-conversation')
            const toPosition = this.findPosition(this.foundElements[index]) - 300
            document.getElementById('messages-container').scroll({
                top: toPosition,
                left: 0,
                behavior: 'smooth',
            })
        },
        findPosition (obj) {
            let currentTop = 0
            if (obj.offsetParent) {
                do {
                    currentTop += obj.offsetTop
                } while ((obj = obj.offsetParent))
                return [currentTop]
            }
        },
        changeAgent () {
            console.log(this.currentConversation, this.currentAgent)
            AiService.changeAssistantToConversation({ ...{ conversationId: this.currentConversation.id }, ...this.currentAgent }).then(() => {
                this.currentConversation.agent_id = this.currentAgent.id
                this.currentConversation.agent_type_id = this.currentAgent.typeId
                this.loadMessages()
            })
        },
        getPrivateWebChatConversations () {
            AiService.getPrivateWebChatConversations().then((conversations) => {
                this.conversations = conversations.map((conversation) => {
                    conversation.last_date = moment(conversation.last_message ? conversation.last_message.created_at : conversation.created_at)
                    conversation.last_date_milis = conversation.last_date.toDate().getTime()
                    return conversation
                })
            })
        },
        setConversation (item) {
            this.currentConversation = item
            const foundAgents = this.agents.filter((agent) => { return agent.id === this.currentConversation.agent_id && agent.typeId === this.currentConversation.agent_type_id })
            this.currentAgent = foundAgents[0]

            if (this.currentConversation.contact) {
                this.contact.loadData(this.currentConversation.contact)
            } else {
                this.contact = new Contact()
            }
            this.loadMessages()
        },
        loadMessages () {
            AiService.getPrivateWebChatMessages({ chatConversationId: this.currentConversation.id }).then((messages) => {
                this.messages = messages.map((message) => {
                    message.parsedMessage = this.parseMessage(message.message)
                    if (message.message_type === 2) {
                        message.interactive_message_data = message.interactive_message_data ? JSON.parse(message.interactive_message_data) : null
                    }
                    return message
                })
                this.scrollDownSmooth()

                if (this.task) {
                    EventBus.$emit('showLoading', true)
                    setTimeout(() => {
                        const messagesContainer = document.getElementsByClassName('conversation-message')
                        const messagesArray = Array.from(messagesContainer)
                        const targetId = '' + this.task.base_message_id
                        let foundElement = null
                        messagesArray.forEach((element) => {
                          if (element.id === targetId) {
                            foundElement = element
                          }
                        })
                        if (foundElement) {
                          const toPosition = this.findPosition(foundElement) - 500
                          document.getElementById('messages-container').scroll({
                            top: toPosition,
                            left: 0,
                            behavior: 'smooth',
                          })

                          foundElement.parentElement.parentElement.style.setProperty('border', '2px solid #2196f3', 'important')
                          this.task = undefined
                          this.conversationIdToLoad = undefined
                        }
                        setTimeout(() => {
                            EventBus.$emit('showLoading', false)
                        }, 1000)
                    }, 2000)
                }
            })
        },

        scrollDownSmooth () {
            const messagesContainer = document.getElementById('messages-container')
            setTimeout(() => {
                messagesContainer.scrollTo({
                    top: messagesContainer.scrollHeight,
                    behavior: 'smooth',
                })
            }, 200)
        },
        sendMessage () {
            if (!this.text || this.text === '') return
            const text = this.text + ''
            this.text = ''
            AiService.savePublicWebChatMessageFromInbox({ uniqueId: this.currentConversation.unique_id, text: text }).then((res) => {
                this.messages.push({
                    id: uuidv4(),
                    is_contact: false,
                    message: text,
                    parsedMessage: this.parseMessage(text),
                    created_at: moment(),
                    agent: this.currentAgent,
                })
                this.scrollDownSmooth()
            })
        },
        filterRoomsInContactUserProp (prop, searchTerm, conversations) {
            return conversations.filter(c => {
                return c.contact
                    ? c.contact[prop] && this.formatString(c.contact[prop]).includes(this.formatString(searchTerm))
                    : c.unique_id?.includes(this.formatString(searchTerm)) || this.formatString(c.sender_name)?.includes(this.formatString(searchTerm))
            })
        },
        formatString (string) {
            return string
                ? string
                    .toLowerCase()
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f]/g, '')
                : string
        },

        preCloseConversation () {
            this.dialogConfirmCloseConversation = true
        },

        closeConversation () {
            this.loadingDialogCloseConversation = true
            AiService.closeConversation({ conversationId: this.currentConversation.id }).then((res) => {
                this.currentConversation.status = 0
                this.dialogConfirmCloseConversation = 0
                this.loadingDialogCloseConversation = false
            })
        },
        orderConversationsByDateDesc (conversations) {
            return conversations.sort((a, b) => {
                return b.last_date_milis - a.last_date_milis
            })
        },
        findConversation (uniqueId) {
            return this.conversations.find(c => c.unique_id === uniqueId)
        },
        outTextAreaMessage () {
            this.textProps.show = false
        },
    },
    computed: {
        filteredConversations () {
            let foundByStatus = this.conversations.filter(c => {
                return c.status === this.statusFilter
            })
            foundByStatus = this.orderConversationsByDateDesc(foundByStatus)

            if (this.searchContactText === null || this.searchContactText === '') {
                return foundByStatus
            }

            const foundByName = this.filterRoomsInContactUserProp('firstname', this.searchContactText, foundByStatus)
            const foundByLastName = this.filterRoomsInContactUserProp('lastname', this.searchContactText, foundByStatus)
            const foundByPhone = this.filterRoomsInContactUserProp('phone', this.searchContactText, foundByStatus)
            const foundByEmail = this.filterRoomsInContactUserProp('email', this.searchContactText, foundByStatus)

            const mergeResult = [...foundByName, ...foundByLastName, ...foundByPhone, ...foundByEmail]

            const uniques = [
                ...new Map(mergeResult.map((item) => [item.id, item])).values(),
            ]

            return this.orderConversationsByDateDesc(uniques)
        },
    },
    mounted () {
        this.textTag = new TextTag(document.getElementById('textInput'))
        document.getElementById('textInput').addEventListener('mouseup', e => {
            const from = this.textTag.textElement.selectionStart
            const to = this.textTag.textElement.selectionEnd
            let newStart = from
            let newEnd = to

            while (this.textTag.textElement.value.substring(newStart, newStart + 1) === ' ') {
                newStart++
            }

            while (this.textTag.textElement.value.substring(newEnd - 1, newEnd) === ' ') {
                newEnd--
            }

            this.textTag.textElement.setSelectionRange(newStart, newEnd)
            const textSelected = this.textTag.textElement.value.substring(from, to)

            if (textSelected.length > 0) {
                this.textProps.x = e.clientX - 600
                this.textProps.show = true
            } else {
                this.textProps.show = false
            }
        })

        const task = JSON.parse(localStorage.getItem('load-conversation-from-task'))

        if (task) {
            EventBus.$emit('showLoading', true)
            localStorage.removeItem('load-conversation-from-task')
            this.task = task
            this.task.base_message_id = parseInt(this.task.base_message_id)
            AiService.getByBaseMessageId({ id: this.task.base_message_id }).then((conversationIdToLoad) => {
                EventBus.$emit('showLoading', false)
                this.conversationIdToLoad = conversationIdToLoad
                setTimeout(() => {
                    const foundConversation = this.filteredConversations.find((c) => {
                        return c.id === this.conversationIdToLoad
                    })
                    this.setConversation(foundConversation)
                    this.selectedConversationIndex = this.filteredConversations.indexOf(foundConversation)
                })
            })
        }
    },
    created () {
        RoutesService.getCurrentUserAllRoutes()
            .then((routes) => {
            Subscriptions.checkSubscription(
                Subscriptions.CHATBOTS,
                () => {
                this.$router.push({ name: this.$route.name, query: this.$route.query }).catch(() => { this.$forceUpdate() })
                },
                () => this.$router.push({ name: 'ChatBotsSubscriptionNeeded' }).catch(() => { this.$forceUpdate() }),
            )
        })

        this.getPrivateWebChatConversations()
        this.getAllCountries()
        AiService.getAssistantsSimpleList().then((res) => {
            res.forEach(agent => {
                const agentObject = new Agent(agent)
                this.agents.push(agentObject)
            })
        })

        if (!document.getElementsByClassName('nrs-web-chat-container').length) {
            const chatContainer = document.createElement('div')
            chatContainer.className = 'nrs-web-chat-container'
            chatContainer.style.position = 'fixed'
            chatContainer.style.bottom = '0'
            chatContainer.style.right = '0'
            chatContainer.style.paddingBottom = '0px'
            chatContainer.style.marginRight = '60px'
            chatContainer.style.height = '70px'
            chatContainer.style.width = '480px'
            chatContainer.closedHeight = '70px'
            chatContainer.openHeight = '470px'
            window.addEventListener('message', (e) => {
                e.data === 'open-webchat'
                    ? chatContainer.style.height = chatContainer.openHeight
                    : e.data === 'close-webchat' && (chatContainer.style.height = chatContainer.closedHeight)
            })

            const config = encodeURIComponent(JSON.stringify({
                origin: 'localhost',
                headerMessage: 'Hola que tal?',
                welcomeMessage: 'Que pasa?',
                inputPlaceholder: 'Di algo...',
                bgTopBar: '#3C362A',
                bgTopBarHover: '#663F46',
                footerBg: '#3C362A',
                chatBg: '#C9D6EA',
                msgUser: '#E8F7EE',
                msgBot: '#8BAAAD',
                msgUserTxt: '#000000',
                msgBotTxt: '#000000',
            }))

            const iframe = document.createElement('iframe')
            iframe.src = `${window.location.origin}/#/web-chat/chat?initiKey=a437e801-296c-4271-b059-c1d4885945db&config=${config}`
            iframe.frameBorder = '0'
            iframe.height = chatContainer.openHeight
            iframe.width = '100%'
            iframe.allowtransparency = 'true'
            iframe.scrolling = 'no'

            chatContainer.appendChild(iframe)

            document.body.appendChild(chatContainer)
        }

        const websocketUrl = getEnv('backend_websocket_url')
        const user = JSON.parse(localStorage.getItem('user'))

        if (!this.socket) {
            this.socket = io(websocketUrl, {
                transports: ['websocket'],
                auth: {
                    service: 'WEBCHATINBOX',
                    userId: user.id,
                },
            })
            // console.log('webchat-in', this.socket)
        }

        // eslint-disable-next-line complexity
        this.socket.on('webchat-message-in', (message) => {
            console.log('webchat chat message', message, this.currentConversation)
            if (message.messageType) {
                switch (message.messageType) {
                    case 'reaction':
                        this.messages.forEach((messageIn) => {
                            if (messageIn.id === message.baseMessageId) {
                                messageIn.reaction_type = message.reactionType
                            }
                        })
                        console.log(this.messages)
                        break
                    case 'new-conversation':
                        this.conversations.push(message)
                        EventBus.$emit('showAlert', 'success', this.$t('Hay una nueva conversación'))
                        break
                }
                return
            }

            if (this.currentConversation && message.uniqueId === this.currentConversation.unique_id) {
                message.agent = this.currentAgent
                message.parsedMessage = this.parseMessage(message.message)
                if (message.message_type === 2) {
                    message.interactive_message_data = message.interactive_message_data ? JSON.parse(message.interactive_message_data) : null
                }
                setTimeout(() => {
                    this.messages.push(message)
                })
                this.scrollDownSmooth()
            } else {
                const conversation = this.findConversation(message.uniqueId)
                conversation.last_message = message
                conversation.last_message.parsedMessage = this.parseMessage(conversation.last_message.message)
                conversation.last_date = moment(conversation.last_message ? conversation.last_message.created_at : conversation.created_at)
                conversation.last_date_milis = conversation.last_date.toDate().getTime()
            }
        })

        this.socket.on('connect', () => {

        })

        this.socket.on('disconnect', () => {

        })
    },
}
